:root {
  --color-bg: hsl(10, 21%, 95%);
  --color-light: hsl(10, 21%, 95%);
  --color-dark: hsl(240, 77%, 5%);
  --color-text: hsl(12, 5%, 4%);
  --color-theme: #6F0564;
}

.App-header {
  background-color: var(--color-bg);
  min-height: calc(100vh - 3.5rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: var(--color-text);
  text-align: center;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
